import { isDefined } from "@clipboard-health/util-ts";
import { type DateRange } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { addDays, differenceInDays, isBefore, parseISO } from "date-fns";
import { useEffect } from "react";

export interface UseLogWorkplaceCalendarStatsEffectProps {
  agentId: string;
  dataSuccessfullyLoaded: boolean;
  numberOfOpenShifts: number;
  calendarDateRange: DateRange;
}

const WORKER_CALENDAR_STATS_EFFECT_LOCAL_STORAGE_KEY = "workerCalendarStatsEffectLastSentAt";

/**
 * @deprecated
 * We have logging capabilities with Braze communication on the `backend-main`.
 * Unfortunately, the API server used for this data is `open-shifts`, which lacks the necessary capabilities. Thus, we need to handle this functionality on the frontend.
 * Intent: This captured event will be utilized by Braze to initiate In-App Messages (IAM) and notifications based on the provided content value.
 * To optimize data usage and minimize costs, we're limiting the frequency of these events sent to Braze, avoiding unnecessary consumption of data points.
 */
export function useLogWorkerCalendarStatsEffect(props: UseLogWorkplaceCalendarStatsEffectProps) {
  const {
    dataSuccessfullyLoaded,
    numberOfOpenShifts,
    calendarDateRange: { startDate, endDate },
    agentId,
  } = props;

  const numberOfDays = differenceInDays(endDate, startDate);

  useEffect(() => {
    const lastLoggedWorkerDateString = localStorage.getItem(
      WORKER_CALENDAR_STATS_EFFECT_LOCAL_STORAGE_KEY
    );
    let lastLoggedWorkerDate: Date | undefined;
    try {
      lastLoggedWorkerDate = lastLoggedWorkerDateString
        ? parseISO(lastLoggedWorkerDateString)
        : undefined;
    } catch {
      // ignore invalid date string
    }

    const hasRecentlyLogged =
      isDefined(lastLoggedWorkerDate) && isBefore(new Date(), addDays(lastLoggedWorkerDate, 5));

    if (
      typeof numberOfDays === "number" &&
      agentId &&
      dataSuccessfullyLoaded &&
      !hasRecentlyLogged
    ) {
      logEvent(APP_V2_APP_EVENTS.WORKER_CALENDAR_STATS, {
        agentId,
        numberOfOpenShifts,
        numberOfDays,
      });
      localStorage.setItem(
        WORKER_CALENDAR_STATS_EFFECT_LOCAL_STORAGE_KEY,
        new Date().toISOString()
      );
    }
  }, [dataSuccessfullyLoaded, agentId, numberOfOpenShifts, numberOfDays]);
}
