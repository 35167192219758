import { z } from "zod";

export enum AgentPreferenceKey {
  QUALIFICATION = "qualification",
  DISTANCE = "distance",
}

const preferenceQualificationSchema = z.object({
  key: z.literal(AgentPreferenceKey.QUALIFICATION),
  value: z.string(),
});

const preferenceDistaceSchema = z.object({
  key: z.literal(AgentPreferenceKey.DISTANCE),
  value: z.number(),
});

export const preferenceRequestSchema = z.discriminatedUnion("key", [
  preferenceQualificationSchema,
  preferenceDistaceSchema,
]);

export const preferenceResponseSchema = z.object({
  _id: z.string(),
  preference: z.object({
    distance: z.number(),
    qualification: z.string(),
  }),
});

export type PreferenceRequest = z.infer<typeof preferenceRequestSchema>;
export type PreferenceResponse = z.infer<typeof preferenceResponseSchema>;

export const agentPreferencesSchema = z.object({
  license: z.string(),
  distance: z.number(),
});

export type AgentPreferences = z.infer<typeof agentPreferencesSchema>;
