import { DeploymentEnvironmentName, environmentConfig } from "@src/appV2/environment";

export function EnvironmentRibbon() {
  if (environmentConfig.REACT_APP_ENVIRONMENT_NAME === DeploymentEnvironmentName.PRODUCTION) {
    return null;
  }

  let environmentName = environmentConfig.REACT_APP_ENVIRONMENT_NAME.slice(0, 3);
  const environmentNameWords = environmentConfig.REACT_APP_ENVIRONMENT_NAME.split("-");
  if (environmentNameWords.length > 1) {
    environmentName = environmentNameWords[0].slice(0, 1) + environmentNameWords[1].slice(0, 2);
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "4rem",
        width: "4rem",
        overflow: "hidden",
        pointerEvents: "none",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "1rem",
          top: "1rem",
          height: "1rem",
          width: "4.5rem",
          textAlign: "center",
          fontSize: "0.8rem",
          transform: "translate(-38px, -8px) rotate(-45deg)",
          backgroundColor: "#d1d5db",
          pointerEvents: "all",
        }}
      >
        <a
          style={{ color: "black", fontWeight: 600, textDecoration: "none" }}
          href="https://www.notion.so/How-SDLC-deviates-from-Production-13d8643321f48062a690ce3c20ace921"
        >
          {environmentName.toUpperCase()}
        </a>
      </div>
    </div>
  );
}
