import { get } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";

import { type ExternalPaymentAccounts, externalPaymentAccounts } from "../types";

export interface UseGetExternalPaymentAccountsProps {
  workerId: string;
}

export const getExternalAccountsPath = (workerId: string) =>
  `/workers/${workerId}/external-payment-accounts`;

export function useGetExternalPaymentAccounts(
  props: UseGetExternalPaymentAccountsProps,
  options: UseQueryOptions<ExternalPaymentAccounts> = {}
): UseQueryResult<ExternalPaymentAccounts> {
  const apiPath = getExternalAccountsPath(props.workerId);
  return useQuery({
    queryKey: [apiPath],
    queryFn: async () => {
      const response = await get({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}${apiPath}`,
        responseSchema: externalPaymentAccounts,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_EXTERNAL_PAYMENT_ACCOUNTS_FAILURE,
    },
    ...options,
  });
}
