import { ErrorPage } from "@src/appV2/Errors/ErrorPage";
import { SupportContext } from "@src/appV2/support/constants";

export function NoAgreementTemplateFileAvailable() {
  return (
    <ErrorPage
      title="Oops! We are having troubles loading this agreement"
      supportContext={SupportContext.NO_AGREEMENT_TEMPLATE_FILE_AVAILABLE}
      description="It might take a little while before it shows up here. Please try again in a moment."
    />
  );
}
