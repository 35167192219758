import { isDefined } from "@clipboard-health/util-ts";
import {
  type AgreementsResponse,
  type SignedAgreementsResponse,
} from "@src/appV2/Agreements/types";

export function getNewestSignedAgreementFromResponse(
  signedAgreementsResponse: SignedAgreementsResponse | AgreementsResponse
) {
  if (!isDefined(signedAgreementsResponse) || !isDefined(signedAgreementsResponse.data)) {
    return undefined;
  }

  const sorted = [...signedAgreementsResponse.data].sort(
    (a, b) => a.attributes.version - b.attributes.version
  );

  return sorted.at(-1);
}
