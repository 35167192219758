import { isDefined } from "@clipboard-health/util-ts";
import { type Worker } from "@src/appV2/Worker/api/types";
import { GET_WORKER_PATH } from "@src/appV2/Worker/api/useGetWorker";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useQueryClient } from "@tanstack/react-query";

import { AgentPreferenceKey, type AgentPreferences } from "./types";
import { useAgentPreferencesMutation } from "./useAgentPreferencesMutation";

export function useAgentPreferences() {
  const queryClient = useQueryClient();

  const worker = useDefinedWorker();

  const { mutateAsync: mutateAgentPreferences } = useAgentPreferencesMutation({
    onMutate: async (newAgentPreferences) => {
      await queryClient.cancelQueries([GET_WORKER_PATH]);

      const previousAgentProfile = queryClient.getQueryData<Worker | undefined>([GET_WORKER_PATH]);

      queryClient.setQueryData<Worker | undefined>([GET_WORKER_PATH], (oldAgentProfile) => {
        if (isDefined(oldAgentProfile)) {
          return {
            ...oldAgentProfile,
            preference: {
              ...oldAgentProfile.preference,
              [newAgentPreferences.key]: newAgentPreferences.value,
            },
          };
        }

        return oldAgentProfile;
      });

      return { previousAgentProfile };
    },
    onError: (_error, _newAgentPreferences, context) => {
      const agentContext = context as { previousAgentProfile: Worker | undefined };

      queryClient.setQueryData<Worker | undefined>(
        [GET_WORKER_PATH],
        agentContext.previousAgentProfile
      );
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: [GET_WORKER_PATH] });
    },
  });

  function setFilters(formData: AgentPreferences) {
    // Save agent preferences if they changed
    if (formData.distance !== worker.preference.distance) {
      void mutateAgentPreferences({
        key: AgentPreferenceKey.DISTANCE,
        value: formData.distance,
      });
    }

    if (formData.license !== worker.preference.qualification) {
      void mutateAgentPreferences({
        key: AgentPreferenceKey.QUALIFICATION,
        value: formData.license,
      });
    }
  }

  return {
    filters: {
      distance: worker.preference.distance,
      license: worker.preference.qualification,
    },
    setFilters,
  };
}
