import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { type ApiResponse, post } from "../../api/api";

const agentLoggedInResponseSchema = z.object({
  success: z.boolean(),
});

export type AgentLoggedInResponse = z.infer<typeof agentLoggedInResponseSchema>;

export const AGENT_LOGGED_IN_PATH = "/agentProfile/loggedIn";

export function useAgentLoggedIn(
  options: UseMutationOptions<ApiResponse<AgentLoggedInResponse>, AxiosError> = {}
): UseMutationResult<ApiResponse<AgentLoggedInResponse>, AxiosError, void> {
  return useMutation({
    mutationFn: async () => {
      return await post({
        url: AGENT_LOGGED_IN_PATH,
        responseSchema: agentLoggedInResponseSchema,
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.POST_AGENT_LOGGED_IN_FAILURE,
    },
    ...options,
  });
}
