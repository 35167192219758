import { z } from "zod";

export enum AgreementKind {
  INDEPENDENT_CONTRACTOR = "independent-contractor",
}

const agreementAttributesSchema = z.object({
  downloadUrl: z.string().url(),
  kind: z.nativeEnum(AgreementKind),
  version: z.number(),
});

export const agreementSchema = z.object({
  type: z.literal("agreement"),
  id: z.string(),
  attributes: agreementAttributesSchema,
});

export const agreementsResponseSchema = z.object({
  data: z.array(agreementSchema),
});

export const agreementsRequestSchema = z.object({
  workerId: z.string().optional(),
  needsSignature: z.boolean().optional(),
  kind: z.array(z.nativeEnum(AgreementKind)).optional(),
});

const signedAgreementAttributesSchema = agreementAttributesSchema.extend({
  signedAt: z.string().datetime(),
});

export const signedAgreementSchema = z.object({
  type: z.literal("signed-agreement"),
  id: z.string(),
  attributes: signedAgreementAttributesSchema,
});

export const signedAgreementsResponseSchema = z.object({
  data: z.array(signedAgreementSchema),
});

export const signedAgreementsRequestSchema = z.object({
  kind: z.array(z.nativeEnum(AgreementKind)).optional(),
  /**
   * If omitted, will return all versions
   */
  version: z.number().optional(),
});

export type SignedAgreementsResponse = z.infer<typeof signedAgreementsResponseSchema>;
export type SignedAgreement = z.infer<typeof signedAgreementSchema>;
export type SignedAgreementsRequestQuery = z.infer<typeof signedAgreementsRequestSchema>;

export type AgreementsResponse = z.infer<typeof agreementsResponseSchema>;
export type Agreement = z.infer<typeof agreementSchema>;
export type AgreementsRequestQuery = z.infer<typeof agreementsRequestSchema>;
