import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { ShiftPeriod } from "@src/appV2/Shifts/Shift/types";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

const recentColleagueSchema = z.object({
  colleagueUserId: z.string(),
  colleagueName: z.string(),
  shiftStart: z.string(),
  facilityName: z.string(),
  shiftName: z.nativeEnum(ShiftPeriod),
});

export type RecentColleague = z.infer<typeof recentColleagueSchema>;

const recentColleaguesResponseSchema = z.array(recentColleagueSchema);

type RecentColleaguesResponse = z.infer<typeof recentColleaguesResponseSchema>;

export const GET_RECENT_COLLEAGUES_PATH = "/v2/shifts/recent-colleagues";

export function useGetRecentColleagues(
  options: UseGetQueryOptions<RecentColleaguesResponse> = {}
): UseQueryResult<RecentColleaguesResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}${GET_RECENT_COLLEAGUES_PATH}`,
    responseSchema: recentColleaguesResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_RECENT_COLLEAGUES_FAILURE,
    },
    ...options,
  });
}
