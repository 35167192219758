import {
  type AgreementsRequestQuery,
  agreementsRequestSchema,
  type AgreementsResponse,
  agreementsResponseSchema,
} from "@src/appV2/Agreements/types";
import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";

export function useGetAgreements(
  queryParams: AgreementsRequestQuery,
  options: UseGetQueryOptions<AgreementsResponse> = {}
): UseQueryResult<AgreementsResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/agreements`,
    requestSchema: agreementsRequestSchema,
    responseSchema: agreementsResponseSchema,
    queryParams,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_AGREEMENTS_FAILURE,
      userErrorMessage: "Something went wrong while loading agreements",
    },
    ...options,
  });
}
