import { Image, Text } from "@clipboard-health/ui-react";
import { Box, Stack } from "@mui/material";
import cbhLogoHorizontalImage from "@src/appV2/lib/assets/images/cbhLogoHorizontal.png";
import { PdfViewerDownloadButton } from "@src/appV2/PdfViewer/PdfViewerDownloadButton";
import { SupportContext } from "@src/appV2/support/constants";
import { HowToContactSupportLink } from "@src/appV2/support/HowToContactSupportLink";

export function UnsupportedPdfPlatformMessage() {
  return (
    <Stack
      alignItems="center"
      direction="column"
      justifyContent="center"
      textAlign="center"
      height="100%"
    >
      <Box p={2}>
        <Text bold sx={{ mb: 2 }}>
          Please download the PDF file to view it in an external application
        </Text>

        <PdfViewerDownloadButton />

        <Text sx={{ mt: 2 }}>
          We are unable to display the PDF file on your device. Please download the PDF file above.
        </Text>

        <Text sx={{ mb: 2 }}>
          If you are having trouble with downloading this file, please reach out to our Support
          team.
        </Text>

        <HowToContactSupportLink supportContext={SupportContext.UNSUPPORTED_PDF_PLATFORM} />

        <Box>
          <Image height={120} src={cbhLogoHorizontalImage} alt="Clipboard Health Logo" />
        </Box>
      </Box>
    </Stack>
  );
}
