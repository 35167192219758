import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { shiftSchema } from "@src/appV2/Shifts/Shift/types";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

const sentHomeRequestSchema = z.object({
  fcm: z.object({
    _id: z.string(),
    reminderSent: z.number(),
    shiftId: z.string(),
    workerId: z.string(),
    reason: z.object({
      type: z.string(),
      description: z.string(),
    }),
    requestLeadTime: z.number(),
    sendBirdMessageId: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
  }),
  earnings: z.number(),
  shift: shiftSchema,
});

export type SentHomeRequest = z.infer<typeof sentHomeRequestSchema>;

const sentHomeRequestsResponseSchema = z.array(sentHomeRequestSchema);

type SentHomeRequestsResponse = z.infer<typeof sentHomeRequestsResponseSchema>;

export function useSentHomeRequests(
  options: UseGetQueryOptions<SentHomeRequestsResponse> = {}
): UseQueryResult<SentHomeRequestsResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/fcm/pending`,
    responseSchema: sentHomeRequestsResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_SENT_HOME_REQUESTS_FAILURE,
    },
    ...options,
  });
}
