import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { type ApiResponse, get } from "../../api/api";

const agentByPhoneRequestSchema = z.object({
  phone: z.string(),
});

/**
 * @deprecated
 * onboardingWorkflow is deprecated - delete from server and remove here
 * Legacy is the only valid one, and it is the standard.
 * https://linear.app/clipboardhealth/issue/FEF-159/delete-experimental-onboarding-v2-and-cya
 */
export enum AgentOnboardingWorkflow {
  MIN = "MIN",
  ALL = "ALL",
  CYAINIT = "CYA-INIT",
  CYAALL = "CYA-ALL",
  CYAMARKETPLACEONLY = "CYA-MARKETPLACE-ONLY",
  CYAADVANCEONLY = "CYA-ADVANCE-ONLY",
  LEGACY = "LEGACY",
  REGIONUNSUPPORTEDMARKETPLACE = "REGION-UNSUPPORTED-MARKETPLACE",
  REGIONUNSUPPORTEDMIN = "REGION-UNSUPPORTED-MIN",
}

const agentByPhoneResponseSchema = z.union([
  z.object({
    onboardingFlags: z.object({
      isSignupInitiated: z.boolean(),
      isSignupCompleted: z.boolean(),
      signupStage: z.string().nullable(),
      signupCompletedAt: z.string().nullable(),
      /** onboardingWorkflow is deprecated - delete from server and remove here */
      onboardingWorkflow: z.nativeEnum(AgentOnboardingWorkflow),
    }),
  }),
  z.string(),
]);

export type AgentByPhoneRequest = z.infer<typeof agentByPhoneRequestSchema>;
export type AgentByPhoneResponse = z.infer<typeof agentByPhoneResponseSchema>;

export const AGENT_PROFILE_BY_PHONE_PATH = "/agentProfile/byPhone";

export function useRequestAgentByPhone(
  options: UseMutationOptions<
    ApiResponse<AgentByPhoneResponse>,
    AxiosError,
    AgentByPhoneRequest
  > = {}
): UseMutationResult<ApiResponse<AgentByPhoneResponse>, AxiosError, AgentByPhoneRequest> {
  return useMutation({
    mutationFn: async (queryParams: AgentByPhoneRequest) => {
      return await get({
        url: AGENT_PROFILE_BY_PHONE_PATH,
        queryParams,
        requestSchema: agentByPhoneRequestSchema,
        responseSchema: agentByPhoneResponseSchema,
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_AGENT_BY_PHONE_FAILURE,
    },
    ...options,
  });
}
