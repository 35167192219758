import {
  type SignedAgreementsRequestQuery,
  signedAgreementsRequestSchema,
  type SignedAgreementsResponse,
  signedAgreementsResponseSchema,
} from "@src/appV2/Agreements/types";
import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";

export interface GetSignedAgreementsParams extends SignedAgreementsRequestQuery {
  workerId: string;
}

export function useGetSignedAgreements(
  params: GetSignedAgreementsParams,
  options: UseGetQueryOptions<SignedAgreementsResponse> = {}
): UseQueryResult<SignedAgreementsResponse> {
  const { workerId, ...queryParams } = params;

  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/workers/${workerId}/signed-agreements`,
    requestSchema: signedAgreementsRequestSchema,
    responseSchema: signedAgreementsResponseSchema,
    queryParams,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_SIGNED_AGREEMENTS_FAILURE,
      userErrorMessage: "Something went wrong while loading signed agreements",
    },
    ...options,
  });
}
